import React from "react";
// import { Link } from "react-router-dom";
import {
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";

// import cn from "classnames";
import css from "./Footer.module.scss";
import commonStyle from "styles/commonStyle/index.module.scss";

const HomePageFooter = (props: any) => {
  return (
    <footer className={css.footer}>
      <div className={css.shape}></div>
      <div className={css.container}>
        <Row gutter={24} className={css.row}>
          <Col lg={24} sm={24} xs={24} className={css.col}>
            {/* <div className={css.footer__main}>
              <div className={css.block_text}>
                <h3 className={commonStyle.longSectionTitle}>
                  Obtain Solutions And Services
                </h3>
                <p>
                  Leave your email and we will contact you as soon as possible
                </p>
              </div>
              <form action="#" className={css.form}>
                <div className={css.form_group}>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Your email"
                  />
                </div>
                <Link
                  to="/contactUs"
                  className={cn(commonStyle.btn, css.contentBtn)}
                >
                  <span>Subscribe</span>
                </Link>
              </form>
            </div> */}
            <div className={css.footer__bottom}>
              <Row gutter={[8, 32]} className={css.footerRow}>
                <Col lg={24} sm={24} xs={24}>
                  <div className={css.sectionWarp}>
                    <div className={commonStyle.longSectionTitle}>
                      {" "}
                      Contact information
                    </div>
                  </div>
                  <p>
                    <PhoneOutlined />
                    &nbsp;&nbsp;+61 874777919
                  </p>
                  <p>
                    <MailOutlined />
                    &nbsp;&nbsp;info@quantdigital.com.au
                  </p>
                  <p>
                    <EnvironmentOutlined />
                    &nbsp;&nbsp;9-15 Field Street, Adelaide, SA 5000
                  </p>
                </Col>
                <Col lg={24} sm={24} xs={24}>
                  <div className={css.navHref}>
                    <ul className={css.list}>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/aboutUs">About Us</a>
                      </li>
                      <li>
                        <a href="/services">Services</a>
                      </li>
                      <li>
                        <a href="/solutions">Solutions & Technology</a>
                      </li>
                      <li>
                        <a href="/contactUs">Contact Us</a>
                      </li>
                    </ul>
                    <p className={css.copyright}>
                      Copyright © 2025, Quant Digital
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <a id="scroll-top" href="/">
        <span className="icon-arrow-top"></span>
      </a>
    </footer>
  );
};

export default HomePageFooter;
